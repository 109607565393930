var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("p", [_vm._v("消息通知")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.createDrawer = true
                }
              }
            },
            [_vm._v("新建消息通知")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "70" }
          }),
          _c("el-table-column", { attrs: { label: "标题", prop: "title" } }),
          _c("el-table-column", {
            attrs: { label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.type === 1
                      ? _c(
                          "el-tag",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("系统公告")]
                        )
                      : row.type === 2
                      ? _c(
                          "el-tag",
                          { attrs: { type: "success", size: "small" } },
                          [_vm._v("内容更新")]
                        )
                      : _c(
                          "el-tag",
                          { attrs: { type: "info", size: "small" } },
                          [_vm._v("其他")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否强制", prop: "is_force" }
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", prop: "create_time" }
          }),
          _c("el-table-column", {
            attrs: { label: "发送时间", prop: "start_time" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.query.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.listTotal
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.query, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.query, "pageSize", $event)
              },
              "current-change": _vm.getList,
              "size-change": _vm.getList
            }
          })
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "80%",
            title: "新建消息",
            visible: _vm.createDrawer,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.createDrawer = $event
            }
          }
        },
        [
          _vm.createDrawer
            ? _c("CreateNotification", { on: { close: _vm.handleCreateClose } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }